.info {
  --icon-color: var(--color-info);
  --icon-color-fg: var(--color-info-fg);
  --stroke-width: 2px !important;

  transform: rotate(180deg);
}

.error {
  --icon-color: var(--color-error);
  --icon-color-fg: var(--color-error-fg);
  --stroke-width: 2px !important;
}

.warning {
  --icon-color: var(--color-warning);
  --icon-color-fg: var(--color-warning-fg);
  --stroke-width: 0 !important;

  background-color: transparent !important;
  border-radius: 0 !important;
}

.success {
  --icon-color: var(--color-success);
  --icon-color-fg: var(--color-success-fg);

  padding: 3px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--rounded-corners-circle-or-square);
  background-color: var(--icon-color);
  height: 100%;
  width: 100%;
  flex-shrink: 0;

  :global(.icon-stroke) {
    height: 100%;
    width: 100%;
    fill: var(--icon-color);
    stroke: var(--icon-color-fg);
  }
}
