@import "~styles/utils/media-queries";

.header {
  font: var(--text-header-lg);
  align-items: center;
  display: flex;
}

.header-container {
  display: flex;
  align-items: center;
  padding: 15px 45px 0 15px;
  gap: 10px;

  @media (--mobile) {
    padding: 15px 15px 0;
  }
}

.fullscreen-header {
  padding: 30px 40px 5px;

  @media (--mobile) {
    padding-top: 15px;
  }
}

.subtitle {
  color: var(--subdued-color);
  margin: 10px 15px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
}

.size-fullscreen {
  .header {
    @media (--mobile) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .subtitle {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.icon {
  height: 32px;
  width: 32px;
  flex-shrink: 0;

  @media (--tablet) {
    height: 30px;
    width: 30px;
  }

  @media (--mobile) {
    height: 26px;
    width: 26px;
  }
}
